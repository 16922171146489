import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import VendorCard from './VendorCard'; // Ensure this component exists
import './styles/VendorMarketplace.css';

const VendorMarketplace = () => {
  const [vendors, setVendors] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const db = getFirestore();

  useEffect(() => {
    const fetchVendors = async () => {
      const querySnapshot = await getDocs(collection(db, 'vendorMarketplace'));
      const vendorList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setVendors(vendorList);
    };

    fetchVendors();
  }, []);

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter vendors based on search term
  const filteredVendors = vendors.filter(vendor => 
    vendor.name && typeof vendor.name === 'string' && vendor.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="vendor-marketplace-container">
      <h1>Vendor Marketplace</h1>
      <input 
        type="text" 
        placeholder="Search vendors" 
        value={searchTerm} 
        onChange={handleSearchChange}
        className="vendor-search"
      />
      {filteredVendors.length === 0 ? (
        <p>COMING SOON</p>
      ) : (
        filteredVendors.map(vendor => (
          <VendorCard key={vendor.id} vendor={vendor} />
        ))
      )}
    </div>
  );
};

export default VendorMarketplace;
