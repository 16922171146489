import React from 'react';
import './styles/BackgroundVideo.css';

const BackgroundVideo = () => {
  return (
    <div className="video-container">
      <video autoPlay loop muted playsInline className="video">
        <source src="/assets/Reciprofy_landingpg_bgvideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay-text">
        <h1>Welcome to Reciprofy</h1>
        <p>Creating value for sports teams, brands, and fans</p>
      </div>
    </div>
  );
};

export default BackgroundVideo;
