import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // Import Firebase Authentication
import { db } from '../firebase'; // Your Firebase configuration file
import { doc, collection, setDoc } from 'firebase/firestore';
import './styles/OnboardingSportsTeam.css'; // Make sure to create and style this CSS file

const OnboardingSportsTeam = () => {
  const [teamName, setTeamName] = useState('');
  const [teamGoals, setTeamGoals] = useState('');
  const [annualSpendingBudget, setAnnualSpendingBudget] = useState('');
  const [procurementList, setProcurementList] = useState('');
  const [userId, setUserId] = useState(null);

  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserId(user.uid);
    } else {
      navigate('/login'); // Redirect to the login page if not authenticated
    }
  }, [auth, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const procurementItems = procurementList.split(',').map(item => item.trim());

    const teamData = {
      teamName,
      teamGoals,
      annualSpendingBudget: Number(annualSpendingBudget),
      procurementList: procurementItems,
    };

    try {
      if (userId) {
        const teamDocRef = doc(collection(db, "user_profiles", userId, "team_data"));
        await setDoc(teamDocRef, teamData);
        navigate('/sportsteam-dashboard'); // Navigate to the sports team dashboard
      }
    } catch (error) {
      console.error("Error creating team data: ", error);
    }
  };

  return (
    <div className="onboarding-sportsteam">
      <h1>Welcome, Sports Team!</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="teamName">Team Name:</label>
          <input
            id="teamName"
            type="text"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="teamGoals">Team Goals:</label>
          <input
            id="teamGoals"
            type="text"
            value={teamGoals}
            onChange={(e) => setTeamGoals(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="annualSpendingBudget">Annual Spending Budget:</label>
          <input
            id="annualSpendingBudget"
            type="number"
            value={annualSpendingBudget}
            onChange={(e) => setAnnualSpendingBudget(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="procurementList">Procurement List (comma-separated):</label>
          <textarea
            id="procurementList"
            value={procurementList}
            onChange={(e) => setProcurementList(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="onboarding-submit">Complete Onboarding</button>
      </form>
    </div>
  );
};

export default OnboardingSportsTeam;
