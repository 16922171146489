import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Importing components
import NavBar from './components/NavBar';
import Home from './components/Home';
import Articles from './components/Articles';
import BackgroundVideo from './components/BackgroundVideo';
import Footer from './components/Footer';
import Signup from './components/Signup';
import SignIn from './components/SignIn';
import UserProfile from './components/UserProfile';
import VendorMarketplace from './components/VendorMarketplace';
import VendorProfile from './components/VendorProfile';
import OnboardingSportsTeam from './components/OnboardingSportsTeam';
import SportsTeamDashboard from './components/SportsTeamDashboard';
import AdminDashboard from './components/AdminDashboard';
import AdminLogin from './components/AdminLogin';

// Stylesheet
import './App.css';

// Create a MUI theme instance.
const theme = createTheme({
  // Your theme customization here
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <NavBar />
        <RouteWrapper />
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

const RouteWrapper = () => {
  return (
    <div className="content-wrap">
      <Routes>
        <Route path="/" element={<><BackgroundVideo /><Home /></>} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/article/:id" element={<Articles />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/vendormarketplace" element={<VendorMarketplace />} />
        <Route path="/vendor/:vendorId" element={<VendorProfile />} />
        <Route path="/onboarding-sportsteam" element={<OnboardingSportsTeam />} />
        <Route path="/sportsteam-dashboard" element={<SportsTeamDashboard />} />
        <Route path="/adminPanelReciprofy_092023MOKK10B" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        {/* Add other routes here if needed */}
      </Routes>
    </div>
  );
};

export default App;
