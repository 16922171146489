import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import './styles/Home.css';

const Home = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      const articlesCollectionRef = collection(db, 'team_articles');
      const querySnapshot = await getDocs(articlesCollectionRef);
      const articlesArray = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setArticles(articlesArray.slice(0, 3)); // Limit to 3 articles for preview
    };

    fetchArticles();
  }, []);

  // Function to strip HTML and return plain text
  const stripHtml = htmlString => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  return (
    <div className="home-container">
      {/* Service Cards Section */}
      <div className="service-cards">
        <div className="service-card">
          <h3>For Sports Teams</h3>
          <p>Connect with brand sponsors and vendors for enhanced operations and grow your revenue.</p>
          <Link to="/signup" className="signup-btn">Sign Up as a Sports Team</Link>
        </div>
        <div className="service-card">
          <h3>For Vendors</h3>
          <p>Sell your product and services to sports organizations and gain distribution.</p>
          <Link to="/signup" className="signup-btn">Sign Up as a Vendor</Link>
        </div>
        <div className="service-card">
          <h3>For Brands</h3>
          <p>Gain distribution and expand your reach by sponsoring sports teams. Target teams that align with your brand values and audience demographics.</p>
          <Link to="/signup" className="signup-btn">Sign Up as a Brand</Link>
        </div>
      </div>

      <h2 className="featured-article-heading">FEATURED ARTICLES</h2>
      <div className="article-previews">
        {articles.map(article => (
          <div key={article.id} className="article-preview">
            <h3>{article.title}</h3>
            <p>{stripHtml(article.content).substring(0, 150)}...</p>
            <Link to={`/article/${article.id}`}>Read More</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
