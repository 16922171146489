import React from 'react';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube'; // Import YouTube icon
import EmailIcon from '@mui/icons-material/Email'; // Import Email icon
import './styles/Footer.css'; // Ensure the path is correct

// Import the NewsletterSignup component
import NewsletterSignup from './NewsletterSignup';

const Footer = () => {
  return (
    <footer className="footer-container" style={{ backgroundImage: "url('/assets/image_fx_create_an_image_for_a_sports_team.png')" }}>
      <NewsletterSignup /> {/* The newsletter signup component */}
      <div className="footer-links-socials-container">
        <div className="footer-links">
          {/* Navigation links */}
          <Link to="/">HOME</Link>
          <Link to="/articles">ARTICLES</Link>
          {/* More links can be added here */}
        </div>
        <div className="footer-socials">
          {/* Social media icons */}
          <a href="https://www.instagram.com/reciprofy" target="_blank" rel="noopener noreferrer">
            <InstagramIcon />
          </a>
          <a href="https://www.linkedin.com/company/reciprofy/" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon />
          </a>
          <a href="https://www.youtube.com/channel/UC1w3P72DFqbMx1-7Y1AFJFg" target="_blank" rel="noopener noreferrer">
            <YouTubeIcon />
          </a>
          <a href="mailto:info@Reciprofy.com" target="_blank" rel="noopener noreferrer">
            <EmailIcon />
            <span>Contact Us: info@Reciprofy.com</span>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
