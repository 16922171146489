// NavBar.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { db } from '../firebase'; // Adjust the path based on your project structure
import { collection, query, where, getDocs } from 'firebase/firestore';
import './styles/NavBar.css'; // Ensure the path to your CSS file is correct

const Navbar = () => {
  const [user, setUser] = useState(null);
  const [isTeamMember, setIsTeamMember] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        // Check if the user is a member of a sports team
        const teamDataQuery = query(collection(db, "user_profiles", currentUser.uid, "team_data"));
        const querySnapshot = await getDocs(teamDataQuery);
        setIsTeamMember(!querySnapshot.empty);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/signin');
      setAnchorEl(null);
    });
  };

  const handleProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="navbar-container">
      <div className="navbar-toolbar">
        <Link to="/" className="navbar-title">
          Reciprofy
        </Link>
        <div className="navbar-links">
          <Link to="/" className="navbar-link">HOME</Link>
          <Link to="/articles" className="navbar-link">ARTICLES</Link>
          <Link to="/vendormarketplace" className="navbar-link">MARKETPLACE</Link>
          {isTeamMember && <Link to="/sportsteam-dashboard" className="navbar-link">TEAM DASHBOARD</Link>}
          {user ? (
            <div className="profile-dropdown-toggle" onClick={handleProfileMenu}>
              PROFILE
              <div className={`navbar-dropdown ${anchorEl ? 'show' : ''}`} onMouseLeave={handleClose}>
                <Link to="/profile" className="dropdown-link">My Profile</Link>
                <span className="dropdown-link" onClick={handleLogout}>Logout</span>
              </div>
            </div>
          ) : (
            <>
              <Link to="/signup" className="navbar-link">SIGNUP</Link>
              <Link to="/signin" className="navbar-link">SIGNIN</Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
