import React from 'react';
import './styles/VendorCard.css'; // Adjust the path as necessary

const VendorCard = ({ vendor }) => {
  return (
    <div className="vendor-card">
      {vendor.media && vendor.media.length > 0 && (
        <div className="vendor-card-header">
          <img src={vendor.media[0].thumbnail} alt={`${vendor.name}`} />
        </div>
      )}
      <div className="vendor-card-body">
        <h3>{vendor.name}</h3>
        <p>{vendor.description}</p>
        <p><strong>Reviews:</strong> {vendor.reviews?.length || 0} reviews</p>
        <p><strong>Discussions:</strong> {vendor.discussions?.length || 0} discussions</p>
        <p><strong>Pricing:</strong> {vendor.pricing}</p>
        {/* Additional details as needed */}
      </div>
      {vendor.media && vendor.media.length > 1 && (
        <div className="vendor-card-footer">
          {vendor.media.slice(1).map((mediaItem, index) => (
            <img key={index} src={mediaItem.thumbnail} alt={`Media ${index + 1}`} />
          ))}
        </div>
      )}
    </div>
  );
};

export default VendorCard;
