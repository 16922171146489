import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import './styles/AdminDashboard.css';


const AdminDashboard = () => {
  const [listings, setListings] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchListings = async () => {
      const querySnapshot = await getDocs(collection(db, 'vendorMarketplace'));
      const listingsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setListings(listingsData);
    };

    fetchListings();
  }, []);

  const updateListingStatus = async (listingId, newStatus) => {
    try {
      const listingRef = doc(db, 'vendorMarketplace', listingId);
      await updateDoc(listingRef, { status: newStatus });

      // Update listings state to reflect the change
      setListings(listings.map(listing => 
        listing.id === listingId ? { ...listing, status: newStatus } : listing
      ));
    } catch (error) {
      console.error('Error updating listing:', error);
    }
  };

  return (
    <div>
      <h1>Admin Dashboard</h1>
      <div className="listings">
        {listings.map(listing => (
          <div key={listing.id} className="listing">
            <h3>{listing.title}</h3>
            <p>Status: {listing.status || 'pending'}</p>
            <button onClick={() => updateListingStatus(listing.id, 'approved')}>
              Approve
            </button>
            <button onClick={() => updateListingStatus(listing.id, 'rejected')}>
              Reject
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
