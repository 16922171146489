import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './firebase';  // This imports and initializes Firebase from firebase.js

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
