import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, updateProfile } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';
import './styles/UserProfile.css';

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [company, setCompany] = useState('');
  const [role, setRole] = useState('');
  const [bio, setBio] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [teamProfileId, setTeamProfileId] = useState(null);
  const [teamProfileData, setTeamProfileData] = useState({});

  const auth = getAuth();

  const fetchUserProfileData = async (uid) => {
    const userProfileRef = doc(db, 'user_profiles', uid);
    const docSnap = await getDoc(userProfileRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      setDisplayName(data.displayName || '');
      setCompany(data.company || '');
      setRole(data.role || '');
      setBio(data.bio || '');
      setImageUrl(data.photoURL || 'default-avatar.png');
      
      // Handle teamProfileId
      if (data.teamProfileId) {
        setTeamProfileId(data.teamProfileId);
        fetchTeamProfileData(data.teamProfileId);
      }
    } else {
      console.log('No such document!');
    }
  };

  const fetchTeamProfileData = async (teamProfileId) => {
    const teamProfileRef = doc(db, 'sportsTeamProfiles', teamProfileId);
    const teamProfileSnap = await getDoc(teamProfileRef);

    if (teamProfileSnap.exists()) {
      setTeamProfileData(teamProfileSnap.data());
    } else {
      console.log('No such team profile!');
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchUserProfileData(currentUser.uid);
      }
    });

    return () => unsubscribe();
  }, [auth]);


  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setProfileImage(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (profileImage && user) {
      const imageRef = storageRef(storage, `profileImages/${user.uid}`);
      setUploading(true);
      try {
        const snapshot = await uploadBytes(imageRef, profileImage);
        const url = await getDownloadURL(snapshot.ref);
        await updateProfile(user, { photoURL: url });
        setImageUrl(url);
      } catch (error) {
        console.error('Error uploading image: ', error);
      }
      setUploading(false);
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    if (user) {
      await updateProfile(auth.currentUser, {
        displayName: displayName,
        photoURL: imageUrl,
      });

      const userProfileRef = doc(db, 'user_profiles', user.uid);
      await setDoc(userProfileRef, {
        displayName,
        company,
        role,
        bio,
        photoURL: imageUrl,
      }, { merge: true });

      alert('Profile updated successfully');
      setEditMode(false);
    }
  };

  return (
    <div className="user-profile-container">
      {editMode ? (
        <form onSubmit={handleProfileUpdate}>
          <label>
            Name:
            <input 
              type="text" 
              value={displayName} 
              onChange={(e) => setDisplayName(e.target.value)} 
            />
          </label>
          <label>
            Company:
            <input 
              type="text" 
              value={company} 
              onChange={(e) => setCompany(e.target.value)} 
            />
          </label>
          <label>
            Role:
            <input 
              type="text" 
              value={role} 
              onChange={(e) => setRole(e.target.value)} 
            />
          </label>
          <label>
            Bio:
            <textarea 
              value={bio} 
              onChange={(e) => setBio(e.target.value)} 
            />
          </label>
          <input type="file" onChange={handleImageChange} />
          {imageUrl && <img src={imageUrl} alt="Profile" />}
          <button onClick={handleUpload} type="button" disabled={uploading}>
            {uploading ? 'Uploading...' : 'Upload Image'}
          </button>
          <button type="submit">Save Changes</button>
          <button onClick={toggleEditMode} type="button">Cancel</button>
        </form>
      ) : (
        <div>
          <img src={imageUrl} alt="Profile" className="profile-avatar" />
          <h2>{displayName}</h2>
          <p>Email: {user?.email}</p>
          <p>Company: {company}</p>
          <p>Role: {role}</p>
          <p>Bio: {bio}</p>
          <button onClick={toggleEditMode}>Edit Profile</button>
        </div>
      )}
  
      {teamProfileId && (
        <div className="team-profile-data">
          <h3>Team Profile</h3>
          <p>Team Name: {teamProfileData.teamName}</p>
          <p>Location: {teamProfileData.location}</p>
          {/* Add more team data fields as needed */}
        </div>
      )}
    </div>
  );
  
};

export default UserProfile;
