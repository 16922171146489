import React, { useState } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path as per your project structure
import './styles/NewsletterSignup.css'; // Path to your CSS file for styling

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setMessage('');

    try {
      await addDoc(collection(db, 'subscribers'), {
        email: email,
        dateSubscribed: serverTimestamp(),
        confirmed: false
      });
      setEmail('');
      setMessage('Thank you for subscribing! Please check your email to confirm.');
    } catch (error) {
      console.error('Error adding subscriber:', error);
      setMessage('An error occurred. Please try again later.');
    }

    setIsLoading(false);
  };

  return (
    <div className="newsletter-signup">
      <h2>Subscribe to Our Newsletter</h2>
      <p>Get the latest news and updates right in your inbox.</p>
      <form onSubmit={handleSubmit}>
        <input 
          type="email" 
          placeholder="Enter your email" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Subscribing...' : 'Subscribe'}
        </button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default NewsletterSignup;
