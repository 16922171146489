import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import './styles/VendorProfile.css'; // Make sure this file exists and is styled properly

const VendorProfile = () => {
  const [vendor, setVendor] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { vendorId } = useParams(); // Retrieves the vendor ID from the URL
  const db = getFirestore();

  useEffect(() => {
    if (!vendorId) {
      setError('Vendor ID not specified');
      setLoading(false);
      return;
    }

    const fetchVendor = async () => {
      setLoading(true);
      try {
        const vendorRef = doc(db, 'vendorMarketplace', vendorId);
        const vendorSnapshot = await getDoc(vendorRef);

        if (vendorSnapshot.exists()) {
          setVendor(vendorSnapshot.data());
        } else {
          setError('Vendor not found');
        }
      } catch (err) {
        console.error("Failed to fetch vendor", err);
        setError('Failed to load vendor data');
      }
      setLoading(false);
    };

    fetchVendor();
  }, [vendorId, db]);

  if (isLoading) {
    return <div className="loading">Loading vendor details...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!vendor) {
    return <div className="vendor-not-found">No such vendor found.</div>;
  }

  // A function to handle rendering of media items, if they exist.
  const renderMediaGallery = (media) => {
    return (
      <div className="vendor-media-gallery">
        {media.map((mediaItem, index) => (
          <img key={index} src={mediaItem.url} alt={`Media ${index}`} className="vendor-media-item" />
        ))}
      </div>
    );
  };

  return (
    <div className="vendor-profile">
      <h1>{vendor.name}</h1>
      <img src={vendor.profileImage} alt={vendor.name} className="vendor-profile-image" />
      <p className="vendor-description">{vendor.description}</p>
      <div className="vendor-details">
        <p><strong>Email:</strong> {vendor.email}</p>
        <p><strong>Location:</strong> {vendor.location}</p>
        <p><strong>Services:</strong> {vendor.services.join(', ')}</p>
        {vendor.media && renderMediaGallery(vendor.media)}
        {/* More details can be added here */}
      </div>
      {/* Consider adding reviews, media gallery, and discussions */}
    </div>
  );
};

export default VendorProfile;
