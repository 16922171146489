import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getFirestore, getDoc } from 'firebase/firestore';
import './styles/SportsTeamDashboard.css';

const SportsTeamDashboard = () => {
  const [teamData, setTeamData] = useState(null);
  const [teamProfileData, setTeamProfileData] = useState(null);
  const [loading, setLoading] = useState(true);

  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userProfileRef = doc(db, "user_profiles", user.uid);
        const userProfileSnap = await getDoc(userProfileRef);

        if (userProfileSnap.exists() && userProfileSnap.data().teamProfileId) {
          const teamDataRef = doc(db, "user_profiles", user.uid, "team_data", userProfileSnap.data().teamProfileId);
          const teamDataSnap = await getDoc(teamDataRef);

          if (teamDataSnap.exists()) {
            setTeamData(teamDataSnap.data());

            const teamProfileRef = doc(db, 'sportsTeamProfiles', userProfileSnap.data().teamProfileId);
            const teamProfileSnap = await getDoc(teamProfileRef);

            if (teamProfileSnap.exists()) {
              setTeamProfileData(teamProfileSnap.data());
            } else {
              console.log("No such team profile!");
            }
          } else {
            console.log("No team data found for the user");
          }
        } else {
          console.log("User profile does not have a team profile ID");
        }
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!teamData) {
    return <div>No team data found</div>;
  }

  return (
    <div className="sports-team-dashboard">
      <h1>{teamData.teamName}</h1>
      <p>Team Goals: {teamData.teamGoals}</p>
      <p>Annual Spending Budget: ${teamData.annualSpendingBudget}</p>
      <div>
        <h2>Procurement List:</h2>
        <ul>
          {teamData.procurementList && teamData.procurementList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>

      {teamProfileData && (
        <div className="team-profile-data">
          <h3>Team Profile</h3>
          <p>Location: {teamProfileData.location}</p>
          <p>Established Year: {teamProfileData.establishedYear}</p>
          {/* Add more team profile fields as needed */}
        </div>
      )}
    </div>
  );
};

export default SportsTeamDashboard;
