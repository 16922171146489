import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import DOMPurify from 'dompurify';
import { db, storage } from '../firebase';
import './styles/Articles.css';

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [featuredArticleId, setFeaturedArticleId] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      const articlesCollectionRef = collection(db, 'team_articles');
      const querySnapshot = await getDocs(articlesCollectionRef);
      const articlesArray = await Promise.all(querySnapshot.docs.map(async (doc) => {
        let articleData = doc.data();

        // Fetching image and video URLs if stored in Firebase Storage
        if (articleData.imageUrl && articleData.imageUrl.startsWith('gs://')) {
          articleData.imageUrl = await fetchMediaUrl(articleData.imageUrl);
        }
        if (articleData.videoUrl && articleData.videoUrl.startsWith('gs://')) {
          articleData.videoUrl = await fetchMediaUrl(articleData.videoUrl);
        }

        return { id: doc.id, ...articleData };
      }));
      setArticles(articlesArray);
    };

    fetchArticles();
  }, []);

  const fetchMediaUrl = async (mediaRef) => {
    try {
      return await getDownloadURL(storageRef(storage, mediaRef));
    } catch (error) {
      console.error("Error fetching media URL: ", error);
      return null;
    }
  };

  const handleFeatureClick = (articleId) => {
     // console.log("Featuring article ID:", articleId); Debugging log
    setFeaturedArticleId(articleId);
  };

  const createMarkup = (htmlContent) => {
    const cleanContent = DOMPurify.sanitize(htmlContent);
    return { __html: cleanContent };
  };

  const renderMedia = (article) => {
    if (article.videoUrl) {
      return isYouTubeUrl(article.videoUrl) ? (
        <iframe src={getYouTubeEmbedUrl(article.videoUrl)} title={article.title} className="article-iframe" allowFullScreen />
      ) : (
        <video controls className="article-video">
          <source src={article.videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    return null;
  };

  const isYouTubeUrl = (url) => url && url.includes("youtube.com");

  const getYouTubeEmbedUrl = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? `https://www.youtube.com/embed/${match[2]}` : null;
  };

  return (
    <div>
      <h1 className="headline">MEET THE GAME CHANGERS</h1>
      <div className="articles-grid">
        {articles.map((article) => (
          <div key={article.id} className={`article-card ${article.id === featuredArticleId ? 'featured-article' : ''}`}>
            <h2>{article.title}</h2>
            {article.imageUrl && <img src={article.imageUrl} alt={article.title} className="article-image" />}
            <button onClick={() => handleFeatureClick(article.id)} className="feature-article-button">
              Feature This Article
            </button>
            {article.content ? (
              <div dangerouslySetInnerHTML={createMarkup(article.content)} />
            ) : (
              <p>No content available.</p>
            )}
            {renderMedia(article)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Articles;
