import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile
} from 'firebase/auth';
import { getFirestore, doc, setDoc, collection, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import GoogleIcon from '@mui/icons-material/Google';
import './styles/Signup.css';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userType, setUserType] = useState('');
    const [teamProfileId, setTeamProfileId] = useState('');
    const [newTeamName, setNewTeamName] = useState('');
    const [isNewTeam, setIsNewTeam] = useState(false); // Track if adding a new team
    const [teamProfiles, setTeamProfiles] = useState([]);
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const auth = getAuth();
    const db = getFirestore();
    const functions = getFunctions();

    useEffect(() => {
        const fetchTeamProfiles = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "sportsTeamProfiles"));
                const teams = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setTeamProfiles(teams);
            } catch (error) {
                console.error("Error fetching team profiles: ", error);
            }
        };

        fetchTeamProfiles();
    }, [db]);

    const createFirestoreUserProfile = async (user, teamId) => {
        await setDoc(doc(db, "user_profiles", user.uid), {
            bio: "",
            company: "",
            displayName: user.displayName || "",
            email: user.email,
            photoURL: user.photoURL || "",
            role: "",
            userCategory: userType,
            userId: user.uid,
            teamProfileId: teamId || '',
        });
    };

    const handleSignupSuccess = (userType) => {
        navigate(userType === 'SportsTeam' ? '/onboarding-sportsteam' : '/profile');
    };

    const handleSignup = async (method, email = null, password = null) => {
        try {
            let userCredential;
            if (method === 'email') {
                userCredential = await createUserWithEmailAndPassword(auth, email, password);
            } else if (method === 'google') {
                const provider = new GoogleAuthProvider();
                userCredential = await signInWithPopup(auth, provider);
            }

            const user = userCredential.user;
            await updateProfile(user, { displayName: userType });

            let teamId = teamProfileId;
            if (userType === 'SportsTeam' && isNewTeam && newTeamName) {
                const createTeam = httpsCallable(functions, 'addNewSportsTeam');
                const newTeamResponse = await createTeam({ name: newTeamName });

                if (newTeamResponse.data && newTeamResponse.data.teamId) {
                    teamId = newTeamResponse.data.teamId;
                } else {
                    throw new Error('Failed to create new team profile');
                }
            }

            await createFirestoreUserProfile(user, teamId);
            handleSignupSuccess(userType);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleNewTeamToggle = (e) => {
        setIsNewTeam(e.target.checked);
        if (e.target.checked) {
            setNewTeamName('');
            setTeamProfileId('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSignup('email', email, password);
    };

    const handleGoogleSignup = () => {
        handleSignup('google');
    };

    return (
        <div className="signup-container">
            <h1>Sign Up</h1>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <select value={userType} onChange={(e) => setUserType(e.target.value)} required>
                    <option value="">Select User Type</option>
                    <option value="SportsTeam">Sports Team</option>
                    <option value="Vendor">Vendor</option>
                    <option value="Athlete">Athlete</option>
                    <option value="Brand">Brand</option>
                </select>
                {userType === 'SportsTeam' && (
                    <>
                        <select value={teamProfileId} onChange={(e) => setTeamProfileId(e.target.value)} disabled={isNewTeam}>
                            <option value="">Select Team</option>
                            {teamProfiles.map(team => (
                                <option key={team.id} value={team.id}>{team.name}</option>
                            ))}
                        </select>
                        <div className="new-team">
                            <input
                                type="checkbox"
                                checked={isNewTeam}
                                onChange={handleNewTeamToggle}
                            />
                            {isNewTeam && (
                                <input
                                    type="text"
                                    placeholder="New Team Name"
                                    value={newTeamName}
                                    onChange={(e) => setNewTeamName(e.target.value)}
                                />
                            )}
                        </div>
                    </>
                )}
                <button type="submit" className="signup-button">Sign Up</button>
                <button onClick={handleGoogleSignup} className="google-signin-button">
                    <GoogleIcon /> Sign Up with Google
                </button>
            </form>
        </div>
    );
};

export default Signup;
