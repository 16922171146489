import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { adminSignIn } from '../api/auth'; // Ensure this path is correct for your project structure

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        //console.log('Attempting to sign in with:', { email, password });  Log the email and password for debugging
        try {
            const response = await adminSignIn(email, password);
            console.log('Sign-in response:', response); // Log the response for debugging
            if (response.data.isAdmin) {
                navigate('/admin/dashboard'); // Redirect to the admin dashboard upon successful login
            } else {
                setError('Access denied: You are not an admin');
            }
        } catch (err) {
            console.error('Sign-in error:', err); // Log the error for debugging
            setError(err.message); // Handle errors such as incorrect credentials or server issues
        }
    };

    return (
        <div className="admin-login-container">
            <h1>Admin Login</h1>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleSubmit} className="admin-login-form">
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit" className="admin-login-button">Login</button>
            </form>
        </div>
    );
};

export default AdminLogin;
